/* Глобальные стили */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #1a1a1a;
  color: #ffffff;
}

/* Основные цвета */
:root {
  --primary-color: #FFD700; /* Фиолетовый */
  --secondary-color: #FFD700; /* Светло-фиолетовый */
  --background-color: #1a1a1a; /* Темный фон */
  --text-color: #ffffff; /* Белый текст */
  --accent-color: #FFD700; /* Акцентный цвет (розовый) */
}

/* Контейнер для контента */
.App {
  text-align: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Секции */
.section {
  font-size: 1em;
  margin: 40px 0;
  padding: 40px 20px;
  background-color: #2a2a2a;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 1s ease-in-out;
}

/* AppBar */
.appbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #2a2a2a;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.appbar-logo img {
  height: 40px;
}

.appbar-links {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-left: 10vw;
}

.appbar-link {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1em;
  cursor: pointer;
  transition: color 0.3s ease;
}

.appbar-link:hover {
  color: var(--primary-color);
}

/* Кнопки */
.button {
  padding: 12px 24px;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  color: var(--text-color);
  border: none;
  border-radius: 25px;
  font-size: 1em;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(138, 43, 226, 0.5);
}

/* Поля ввода */
.input {
  padding: 12px;
  width: 70%;
  margin-right: 10px;
  border: 2px solid var(--primary-color);
  border-radius: 25px;
  background-color: #1a1a1a;
  color: var(--text-color);
  font-size: 1em;
  outline: none;
}

.input:focus {
  border-color: var(--secondary-color);
  box-shadow: 0 0 10px rgba(138, 43, 226, 0.5);
}

/* Калькулятор для социальных групп */
.social-calculator {
  margin-top: 40px;
  padding: 20px;
  background-color: #3a3a3a;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.social-calculator h3 {
  color: var(--primary-color);
}

.social-calculator span {
  background-color: var(--primary-color);
  color: white;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 1em;
  margin-left: 5px;
}

/* Стили для ошибок */
.error {
    color: #ff6f61;
    margin-top: 10px;
    font-size: 1em;
}


.header-image{
  width: 20vw;
  height: auto;
  border-radius: 50%;
}


.logo-style{
  border-radius: 50%;
}
