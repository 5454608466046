/* Roadmap */
.roadmap-container {
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
}

.roadmap-column {
    flex: 1;
}

.roadmap-column h3 {
    align-items: center;
    gap: 10px;
    color: var(--primary-color);
    font-size: 1.2em;
    margin-bottom: 20px;
}

.roadmap-card {
    background-color: #3a3a3a;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.roadmap-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.roadmap-card h4 {
    margin: 0;
    font-size: 1em;
    color: var(--primary-color);
}

.roadmap-card p {
    margin: 10px 0 0;
    color: #ccc;
}

.roadmap-card.completed {
    opacity: 0.7;
    border-left: 4px solid var(--primary-color);
}

.roadmap-card.completed h4 {
    color: #FFD700;
}

/* Анимации */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.roadmap-card {
    animation: fadeInUp 0.5s ease-in-out;
}