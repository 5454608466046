.footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
    margin-top: auto;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
  
  .footer-link {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-link:hover {
    color: #ccc;
    text-decoration: underline;
  }
  
  .footer-copyright {
    font-size: 0.9em;
    opacity: 0.8;
  }